import React, { useState, useEffect, useCallback } from 'react';
import { API_ADDRESS, PAGE_TITLE_BASE } from './../../constants.js';
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { Dimmer, Loader, Segment, Header, Grid, Divider } from 'semantic-ui-react'
import { References } from './../subcomponents/References';

export function DiscographyView (props) {
	const {mode, key} = useParams();
	const [modeUpdated, setModeUpdated] = useState(mode);
	const [keyUpdated, setKeyUpdated] = useState(key);
	useEffect(() => { if(modeUpdated !== mode ) { setModeUpdated(mode); setLoading(true) } }, [modeUpdated, mode])
	useEffect(() => { if(keyUpdated !== key ) { setKeyUpdated(key); setLoading(true) } }, [keyUpdated, key])

	const [data, setData] = useState({});
	const [pageTitle, setPageTitle] = useState('');
	const [loading, setLoading] = useState(true);

	const navigate = useNavigate();

	const search = useCallback(() => {
		fetch(`${API_ADDRESS}/discography_view/${modeUpdated}/${keyUpdated}`)
			.then(function(response) {
				return response.json()
			}).then(function(json) {
				if(json.length === 0 ) {
					navigate('/404');
				}
				let title = 'Discography View: ' + modeUpdated.charAt(0).toUpperCase() + modeUpdated.slice(1) + ': ' + json[0]['key_nice'];
				setPageTitle(title);
				document.title = title + ' | ' + PAGE_TITLE_BASE;
				setData(json[0]);
				setLoading(false);
			}).catch(function(ex) {
				console.log(ex);
				navigate('/500');
			})
	}, [modeUpdated, keyUpdated, navigate]);

	useEffect(() => { if(loading ) { search() } }, [loading, search])

	return (
		<Segment inverted style={{minHeight:'500px', margin:'1%'}}>
			<Dimmer active={loading}>
				<Loader size='large'>Loading</Loader>
			</Dimmer>
			<Grid.Row>
				<Header as='h1' inverted style={{textAlign:'center'}}>{pageTitle}</Header>
				{'generated' in data && <small>Generated at {data.generated}</small>}
			</Grid.Row>
			{'events' in data && data.events.map(function(session) {
				return <Segment inverted key={session._id}>
					<Divider inverted/>
					<Header as='h2' inverted>{session.date}</Header>
					<Header as='h3' inverted>{session.artist}</Header>
					{'type' in session &&
						<p><b>Type:</b> {session.type}</p>
					}
					{'organisations' in session &&
						<p><b>Label(s):</b> {session.organisations}</p>
					}
					{'session_number' in session &&
						<p><b>Session #:</b> {session.session_number}</p>
					}
					{'film' in session &&
						<p><b>Film:</b> {session.film}</p>
					}
					{'television' in session &&
						<p><b>Television:</b> {session.television}</p>
					}
					{'radio' in session &&
						<p><b>Radio:</b> {session.radio}</p>
					}
					{'location' in session &&
						<p><b>Location:</b> {session.location}</p>
					}
					{'musicians' in session && 
						<p>{session.musicians}</p>
					}
					{'musicians_notes' in session && 
						<p><i>{session.musicians_notes}</i></p>
					}
					{'discographical-notes' in session &&
						<p><i>{session['discographical-notes']}</i></p>
					}
					<br/><br/>
					<ol style={{listStyle:'lower-alpha'}}>
					{'recordings' in session && session.recordings.map(function(recording, i) {
						return <li key={'recording_' + i}><Segment inverted>
							{'master' in recording && recording.master}
							{!('master' in recording) && <span>&emsp;&emsp;&emsp;</span>}
							&emsp;
							{recording.name}
							{session.artist != recording.artist &&
								<>
									<br/>
									&emsp;&emsp;&emsp;
									{recording.artist}
								</>
							}
							<br/>
							&emsp;&emsp;&emsp;
							(
								{recording.writers}
								{'arrangers' in recording && <span> - {recording.arrangers}</span>}
							)
							{'session_notes' in recording && 
								<p><i>{recording.session_notes}</i></p>
							}
							<br/>
							{'notes' in recording &&
								<p><i>{recording.notes}</i></p>
							}
							{'discographical-notes' in recording &&
								<p><i>{recording['discographical-notes']}</i></p>
							}
							<ol style={{listStyle:'lower-roman'}}>
								{'takes' in recording && recording.takes.map(function(take, i) {
									return <li key={'take_' + i}><Segment inverted>
										<b>{take.name}</b>&emsp;[{take.type}]&emsp;{'length' in take && <span>({take.length})</span>}
										<br/>
										{'recording_notes' in take && 
											<p><i>{take.recording_notes}</i></p>
										}
										{'take_notes' in take && 
											<p><i>{take.take_notes}</i></p>
										}
										{'notes' in take &&
											<p><i>{take.notes}</i></p>
										}
										{'releases' in take &&
											<Segment inverted>
												{take.releases.map(function(release, i) {
													return <p key={'release_' + i}>
														&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
														{'year' in release && release.year}
														{!('year' in release) && <>&emsp;</>}
														<>: </>{release.title} ({release.format}, {release.labels}, {release.country})
													</p>
												})}
											</Segment>
										}										
										{'soundtracks' in take &&
											<Segment inverted>
												{take.soundtracks.map(function(soundtrack, i) {
													return <p key={'soundtrack_' + i}>
														&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
														{'year' in soundtrack && soundtrack.year}
														{!('year' in soundtrack) && <>&emsp;</>}
														<>: </>{soundtrack.title} ({soundtrack.type})
													</p>
												})}
											</Segment>
										}										
									</Segment></li>
								})}
							</ol>
						</Segment></li>
					})}
					</ol>
				</Segment>
			})}
			{('references' in data && data.references.length > 0) &&
				<Grid.Row>
					<References references={data.references}/>
				</Grid.Row>
			}
		</Segment>
	);
}