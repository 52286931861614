import React, { useState, useEffect, useCallback } from 'react';
import { API_ADDRESS, PAGE_TITLE_BASE } from './../constants.js';
import { Container, Grid, Dimmer, Loader, Header, Segment } from 'semantic-ui-react'
import { Link } from "react-router-dom";

export function DiscographyViewHome(props) {
	document.title = 'Discography View | ' + PAGE_TITLE_BASE;

	const [loading, setLoading] = useState(true);
	const [searching, setSearching] = useState(false);

	const [data, setData] = useState({});

	const search = useCallback(() => {
		fetch(`${API_ADDRESS}/discography_view/`)
			.then(function(response) {
				return response.json()
			}).then(function(json) {
				setData(json);
				setLoading(false);
			}).catch(function(ex) {
			})
	}, []);	

	useEffect(() => { if(loading && !searching ) { setSearching(true); search() } }, [loading, search, searching])

	return (
		<Container>
			<Grid.Row>
				<Header as='h1' inverted>Discography View Prototype</Header>
				<p>Prototype of simplified Discography View</p>
			</Grid.Row>
			<Dimmer active={loading}>
				<Loader size='large'>Loading</Loader>
			</Dimmer>
			{Object.keys(data).sort().map(function(mode) {
				return <Segment inverted key={mode}>
					<Header as='h2' inverted style={{textTransform:'capitalize'}}>{mode}</Header>
					<ul>
						{data[mode].sort((a, b) => a['key_nice'].localeCompare(b['key_nice'])).map(function(key) {
							return <li key={key['key']}><Link to={"/discography_view/" + mode + "/" + key['key']}>{key['key_nice']}</Link></li>
						})}
					</ul>
				</Segment>
			})}
		</Container>
	);
}