import 'react-app-polyfill/stable';
import 'whatwg-fetch';

import React from 'react';
import {createRoot} from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css'

import './index.css';

import { Header } from './components/layouts/Header.js';
import { Footer } from './components/layouts/Footer.js';
import { Home } from './components/Home.js';

import { Recording } from './components/views/Recording';
import { RecordingBrowse } from './components/browse/RecordingBrowse';
import { Release } from './components/views/Release';
import { ReleaseBrowse } from './components/browse/ReleaseBrowse';

import { MerchandiseBrowse } from './components/browse/MerchandiseBrowse';
import { SheetMusicBrowse } from './components/browse/SheetMusicBrowse';
import { SongBrowse } from './components/browse/SongBrowse';
import { PersonBrowse } from './components/browse/PersonBrowse';
import { OrganisationBrowse } from './components/browse/OrganisationBrowse';
import { AlbumBrowse } from './components/browse/AlbumBrowse';
import { PublicationBrowse } from './components/browse/PublicationBrowse';
import { AdvertBrowse } from './components/browse/AdvertBrowse';
import { YearByYear } from './components/browse/YearByYear';
import { SoundtrackBrowse } from './components/browse/SoundtrackBrowse';

import { ComingSoon } from './components/ComingSoon';
import { InternalServerError } from './components/InternalServerError';
import { PageNotFound } from './components/PageNotFound';
import { Plan2024 } from './components/Plan2024';
import { InformationPlease } from './components/InformationPlease';

import { DiscographyViewHome } from './components/DiscographyViewHome';
import { DiscographyView } from './components/views/DiscographyView';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<BrowserRouter>
		<Header/>
		<Routes>		
			<Route path="/recording/" element={<RecordingBrowse/>}/>
			<Route path="/recording/view/:id" element={<Recording/>}/>
			<Route path="/recording/:id" element={<Recording/>}/>

			<Route path="/release/" element={<ReleaseBrowse/>}/>
			<Route path="/release/view/:id" element={<Release/>}/>
			<Route path="/release/:id" element={<Release/>}/>

			<Route path="/merchandise" element={<MerchandiseBrowse/>}/>
			<Route path="/merchandise/:id" element={<MerchandiseBrowse/>}/>

			<Route path="/sheet_music" element={<SheetMusicBrowse/>}/>
			<Route path="/sheet_music/:id" element={<SheetMusicBrowse/>}/>

			<Route path="/song" element={<SongBrowse/>}/>
			<Route path="/song/:id" element={<SongBrowse/>}/>

			<Route path="/person" element={<PersonBrowse/>}/>
			<Route path="/person/:id" element={<PersonBrowse/>}/>

			<Route path="/organisation" element={<OrganisationBrowse/>}/>
			<Route path="/organisation/:id" element={<OrganisationBrowse/>}/>

			<Route path="/album" element={<AlbumBrowse/>}/>
			<Route path="/album/:id" element={<AlbumBrowse/>}/>

			<Route path="/calendar" element={<YearByYear/>}/>
			<Route path="/calendar/:id" element={<YearByYear/>}/>
			<Route path="/event" element={<YearByYear/>}/>
			<Route path="/event/:event_id" element={<YearByYear/>}/>
			<Route path="/radio" element={<YearByYear/>}/>
			<Route path="/radio/:radio_id" element={<YearByYear/>}/>
			<Route path="/television" element={<YearByYear/>}/>
			<Route path="/television/:television_id" element={<YearByYear/>}/>
			<Route path="/film" element={<YearByYear/>}/>
			<Route path="/film/:film_id" element={<YearByYear/>}/>
			<Route path="/photo" element={<YearByYear/>}/>
			<Route path="/photo/:photo_id" element={<YearByYear/>}/>
			<Route path="/album" element={<YearByYear/>}/>
			<Route path="/album/:album_id" element={<YearByYear/>}/>

			<Route path="/publication" element={<PublicationBrowse/>}/>
			<Route path="/publication/:id" element={<PublicationBrowse/>}/>

			<Route path="/advert" element={<AdvertBrowse/>}/>
			<Route path="/advert/:id" element={<AdvertBrowse/>}/>

			<Route path="/soundtrack" element={<SoundtrackBrowse/>}/>
			<Route path="/soundtrack/:id" element={<SoundtrackBrowse/>}/>

			<Route path="/organisation/*" element={<ComingSoon/>}/>

			<Route path="/discography_view" element={<DiscographyViewHome/>} /> 
			<Route path="/discography_view/:mode/:key" element={<DiscographyView/>} /> 

			<Route path="/2024" element={<Plan2024/>} /> 
			<Route path="/information_please" element={<InformationPlease/>} /> 
			<Route path="/404" element={<PageNotFound/>} /> 
			<Route path="/500" element={<InternalServerError/>} /> 
			<Route path="*" element={<Home/>} /> 
		</Routes>
		<Footer/>
	</BrowserRouter>
);