import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { API_ADDRESS } from './../../constants.js';

import { Icon, Table, Dimmer, Loader, Segment, Button, Modal, Grid, Form, Pagination, Input, Header } from 'semantic-ui-react'
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import useUrlState from '@ahooksjs/use-url-state';

export function ReleaseBrowseCommon(props) {
	const navigate = useNavigate();
	const ref = React.useRef(null);

	const [firstLoad, setFirstLoad] = useState(true);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState(0);
	const [resetPage, setResetPage] = useState(false);
	const [changePage, setChangePage] = useState(false);
	const [searching, setSearching] = useState(false);
	const [rows, setRows] = useState([]);
	const [first, setFirst] = useState(0);
	const [last, setLast] = useState(0);
	const [showVariantFilterModal, setShowVariantFilterModal] = useState(false);
	const [event_variant, setEventVariant] = useState(0);
	const [film_variant, setFilmVariant] = useState(0);
	const [radio_variant, setRadioVariant] = useState(0);
	const [television_variant, setTelevisionVariant] = useState(0);
	const [advert_variant, setAdvertVariant] = useState(0);

	let stateTmp = {
		page: 1,
		sort: "date",
		query: "",
	};

	const [numberOfRows] = useState(50);
	const [urlState, setUrlState] = useUrlState(stateTmp,
		{
			parseOptions: {
				arrayFormat: 'string',
			},
			stringifyOptions: {
				arrayFormat: 'string',
			},
		}
	);
	let stateToUse = props.urlParams ? urlState : stateTmp;
	const [state, setState] = useState(stateToUse);

	const [queryTmp, setQueryTmp] = useState(state.query);
	const [pages, setPages] = useState(state.page);
	const [sortByDate, setSortByDate] = useState(state.sort === 'date');
	const [sortByTitle, setSortByTitle] = useState(state.sort === 'title');

	const stateHelper = useCallback((stateTmp) => {
		let stateToSet = {};
		if(stateTmp.page !== undefined && Number(state.page) !== Number(stateTmp.page)) {
			stateToSet['page'] = Number(stateTmp.page);
		}
		for(const key in stateTmp) {
			if(key === 'page') { continue; }
			if(stateTmp[key] !== undefined && state[key] !== stateTmp[key]) {
				stateToSet[key] = stateTmp[key];
			}
		}
		if(Object.keys(stateToSet).length > 0) {
			setState(prevState => ({...prevState, ...stateToSet}));
			if(props.urlParams) {
				setUrlState(prevState => ({...prevState, ...stateToSet}));
			}
		}
	}, [state, setState, props.urlParams, setUrlState]);

	const search = useCallback(() => {
		let pagetmp = state.page;
		if(resetPage || Number(pagetmp) === 0 ) {
			pagetmp = 1;
		}
	
		var urlRoot = `${API_ADDRESS}/release/browse`;
		var url = `?page=${pagetmp}`;
		var urlAttachment = '&sort=' + state.sort + '&limit=' + numberOfRows;
		if(state.query) {
			urlAttachment += '&query=' + encodeURIComponent(state.query);
		}
		if(props.take) {
			urlAttachment += '&take=' + props.take;
		}
		if(props.variant && props.take) {
			urlAttachment += '&variant=' + props.variant;
		}
		if(props.event) {
			urlAttachment += '&event=' + props.event;
		}
		if(event_variant) {
			urlAttachment += '&event_variant=' + event_variant;
		}
		if(props.film) {
			urlAttachment += '&film=' + props.film;
		}
		if(film_variant) {
			urlAttachment += '&film_variant=' + film_variant;
		}
		if(props.radio) {
			urlAttachment += '&radio=' + props.radio;
		}
		if(radio_variant) {
			urlAttachment += '&radio_variant=' + radio_variant;
		}
		if(props.television) {
			urlAttachment += '&television=' + props.television;
		}
		if(television_variant) {
			urlAttachment += '&television_variant=' + television_variant;
		}
		if(props.advert) {
			urlAttachment += '&advert=' + props.advert;
		}
		if(advert_variant) {
			urlAttachment += '&advert_variant=' + advert_variant;
		}
		if(props.label) {
			urlAttachment += '&label=' + props.label;
		}
		if(props.album) {
			urlAttachment += '&album=' + props.album;
		}
		if(props.release) {
			urlAttachment += '&release=' + props.release;
		}
		// if(props.urlParams) {
		// 	navigate(location.pathname + url + urlAttachment);
		// }

		fetch(urlRoot + url + urlAttachment)
			.then(function(response) {
				return response.json()
			})
			.then(function(json) {
				setTotal(json.count);
				setResetPage(false);
				stateHelper({page: json.page});
				setPages(json.pages);
				setLoading(false);
				setChangePage(false);

				var rows = json.releases;
				setRows(rows);

				var first_tmp = (json.page-1)*numberOfRows+1;
				var last_tmp = (json.page)*numberOfRows;
				if(first_tmp < 0 ) {
					first_tmp = 0;
				}
				if(first_tmp > json.count ) {
					first_tmp = 0;
					last_tmp = 0;
				}
				if(last_tmp > json.count ) {
					last_tmp = json.count;
				}
				setFirst(first_tmp);
				setLast(last_tmp);

				setSearching(false);
				if(!firstLoad) {
					ref.current.scrollIntoView({behavior: 'smooth'});
				}

				if(firstLoad && rows.length === 0 && props.relatedRendered) {
					props.relatedRendered(false);
				}
				setFirstLoad(false);
			})
			.catch(function(ex) {
				setSearching(false);
				console.log(ex);
				// navigate('/500');
			})
	}, [numberOfRows, state, stateHelper, resetPage, firstLoad, props, event_variant, film_variant, radio_variant, television_variant, advert_variant]);	


	const handlePaginationClick = (e, { activePage }) => stateHelper({page: activePage});
	const changeSortField = (field) => stateHelper({sort:field});

	useEffect(() => { 
		if(props.urlParams) {
			let stateToSet = {};
			for(const key in urlState) {
				if(key === 'page') {
					if(urlState.page !== undefined && Number(state.page) !== Number(urlState.page)) {
						stateToSet['page'] = Number(urlState.page);
					}
				} else {
					if(urlState[key] !== undefined && state[key] !== urlState[key]) {
						stateToSet[key] = urlState[key];
					}
				}
			}
			if(Object.keys(stateToSet).length > 0) {
				setState(prevState => ({...prevState, ...stateToSet}));
				if(stateToSet.query) {
					setQueryTmp(stateToSet.query);
					setLoading(true);
				}
			}
		}
	}, [urlState, state, props.urlParams]);

	useEffect(() => { if(loading && !searching ) { setSearching(true); search() } }, [loading, search, searching])
	useEffect(() => { if(changePage ) { setLoading(true); } }, [changePage])
	useEffect(() => { setChangePage(true) }, [state.page, state.sort])
	useEffect(() => {
		if(state.sort === 'title') {
			setSortByTitle(true);
			setSortByDate(false);
		} else {
			setSortByTitle(false);
			setSortByDate(true);
		}
		setResetPage(true);
		setChangePage(true);
	}, [state.sort])

	const clearVariantFilter = () => {
		setResetPage(true);
		setChangePage(true);
		setShowVariantFilterModal(false);
		setEventVariant(0);
		setFilmVariant(0);
		setAdvertVariant(0);
	}
	const runVariantFilters = () => {
		setResetPage(true);
		setChangePage(true);
		setShowVariantFilterModal(false);
	}

	const handleClick = (event, release) => {
		if (event.ctrlKey) {
			window.open(`/release/${release}`, "_blank")
		} else {
			navigate(`/release/${release}`)
		}
	}

	var variantFilterText = '';
	if(props.event) {
		if(event_variant && props.event_variants) {
			for(var variantNum in props.event_variants) {
				if(event_variant === props.event_variants[variantNum]._id) {
					variantFilterText = props.event_variants[variantNum].name;
				}
			}
		}
	}
	if(props.film) {
		if(film_variant && props.film_variants) {
			for(variantNum in props.film_variants) {
				if(film_variant === props.film_variants[variantNum]._id) {
					variantFilterText = props.film_variants[variantNum].name;
				}
			}
		}
	}
	if(props.radio) {
		if(radio_variant && props.radio_variants) {
			for(variantNum in props.radio_variants) {
				if(radio_variant === props.radio_variants[variantNum]._id) {
					variantFilterText = props.radio_variants[variantNum].name;
				}
			}
		}
	}
	if(props.television) {
		if(television_variant && props.television_variants) {
			for(variantNum in props.television_variants) {
				if(television_variant === props.television_variants[variantNum]._id) {
					variantFilterText = props.television_variants[variantNum].name;
				}
			}
		}
	}
	if(props.advert) {
		if(advert_variant && props.advert_variants) {
			for(variantNum in props.advert_variants) {
				if(advert_variant === props.advert_variants[variantNum]._id) {
					variantFilterText = props.advert_variants[variantNum].name;
				}
			}
		}
	}

	let showVariantModal = (props.event && 'event_variants' in props && props.event_variants && props.event_variants.length) ||
							(props.film && 'film_variants' in props && props.film_variants && props.film_variants.length) ||
							(props.radio && 'radio_variants' in props && props.radio_variants && props.radio_variants.length) ||
							(props.television && 'television_variants' in props && props.television_variants && props.television_variants.length) ||
							(props.advert && 'advert_variants' in props && props.advert_variants && props.advert_variants.length);
	let showVariantColumn = showVariantModal || (props.take && 'variants' in props && props.variants && props.variants.length);
							
	return (
		<div ref={ref}>
			<Segment inverted className="browse-table-segment">
				<Dimmer active={loading}>
					<Loader size='large'>Loading</Loader>
				</Dimmer>
				<Grid columns={3} stackable relaxed='very' className="width100">
					<Grid.Column>
						<Button.Group>
							<Button positive={sortByTitle} onClick={() => changeSortField('title')}>Sort by Title</Button>
							<Button.Or />
							<Button positive={sortByDate} onClick={() => changeSortField('date')}>Sort by Year</Button>
						</Button.Group>
					</Grid.Column>
					<Grid.Column>
						<Input inverted fluid
							action={{ icon: 'search', onClick: (e) => {setResetPage(true); setChangePage(true);} }}  
							placeholder='Search...'
							value={queryTmp}
							onChange={(e, { value }) => setQueryTmp(value)}
							onBlur={(e) => stateHelper({query:queryTmp})}
							onKeyUp={(e) => {if (e.key === "Enter") {stateHelper({query:queryTmp}); setResetPage(true); setChangePage(true);}}}
						/>
					</Grid.Column>
					<Grid.Column>
						{showVariantModal &&
							<>
								<Button onClick={() => setShowVariantFilterModal(true)}>
									Variant Filter
								</Button>
								{variantFilterText && <p><i>Variant filter active: {variantFilterText}</i></p>}
							</>
						}
					</Grid.Column>
				</Grid>
				<Table celled inverted selectable>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Title</Table.HeaderCell>
							<Table.HeaderCell>Artist</Table.HeaderCell>
							<Table.HeaderCell>Year</Table.HeaderCell>
							<Table.HeaderCell>Label</Table.HeaderCell>
							<Table.HeaderCell>Country</Table.HeaderCell>
							<Table.HeaderCell>Catalog</Table.HeaderCell>
							<Table.HeaderCell>Format</Table.HeaderCell>
							<Table.HeaderCell>Status</Table.HeaderCell>
							<Table.HeaderCell>Pressing</Table.HeaderCell>
							{showVariantColumn && 
								<Table.HeaderCell>Variant(s)</Table.HeaderCell>
							}
							<Table.HeaderCell className="view-headercell">View</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{rows.length === 0 && 
								<Table.Row>
									<Table.Cell colSpan={8}>
										<Segment placeholder inverted>
											<Header icon inverted>
												<Icon name='search' />
												No releases match your query
											</Header>
										</Segment>
									</Table.Cell>
								</Table.Row>
						}
						{rows.map(function(release){
							let variantTxt = '';
							if(props.take && ('variants' in release || 'event_variants' in release) && 'variants' in props && props.variants && props.variants.length) {
								let takeCount = release.takes.filter(x => x === props.take).length;
								let variantCount = 0;
								if(props.take && 'variants' in release && 'variants' in props && props.variants && props.variants.length) {
									for(var variantNum in props.variants) {
										if(release.variants.includes(props.variants[variantNum]._id)) {
											if(variantTxt !== "") {
												variantTxt += "\n";
											}
											variantTxt += props.variants[variantNum].name;
											variantCount++;
										}
									}
								}
								if(props.take && 'event_variants' in release && 'event_variants' in props && props.variants && props.variants.length) {
									for(variantNum in props.variants) {
										if('_type' in props.variants[variantNum] && props.variants[variantNum]._type === 'event_variant') {
											if(release.event_variants.includes(props.variants[variantNum]._id)) {
												if(variantTxt !== "") {
													variantTxt += "\n";
												}
												variantTxt += props.variants[variantNum].name;
												variantCount++;
											}
										}
									}
								}
								if(props.take && 'radio_variants' in release && 'radio_variants' in props && props.variants && props.variants.length) {
									for(variantNum in props.variants) {
										if('_type' in props.variants[variantNum] && props.variants[variantNum]._type === 'radio_variant') {
											if(release.radio_variants.includes(props.variants[variantNum]._id)) {
												if(variantTxt !== "") {
													variantTxt += "\n";
												}
												variantTxt += props.variants[variantNum].name;
												variantCount++;
											}
										}
									}
								}
								if(variantCount > 0 && variantCount !== takeCount) {
									variantTxt += "\nFull recording";
								}
							}
							if(props.event && 'event_variants' in release && 'event_variants' in props && props.event_variants && props.event_variants.length) {
								for(variantNum in props.event_variants) {
									if(release.event_variants.includes(props.event_variants[variantNum]._id)) {
										if(variantTxt !== "") {
											variantTxt += "\n";
										}
										variantTxt += props.event_variants[variantNum].name;
									}
								}
							}
							if(props.film && 'film_variants' in release && 'film_variants' in props && props.film_variants && props.film_variants.length) {
								for(variantNum in props.film_variants) {
									if(release.film_variants.includes(props.film_variants[variantNum]._id)) {
										if(variantTxt !== "") {
											variantTxt += "\n";
										}
										variantTxt += props.film_variants[variantNum].name;
									}
								}
							}
							if(props.radio && 'radio_variants' in release && 'radio_variants' in props && props.radio_variants && props.radio_variants.length) {
								for(variantNum in props.radio_variants) {
									if(release.radio_variants.includes(props.radio_variants[variantNum]._id)) {
										if(variantTxt !== "") {
											variantTxt += "\n";
										}
										variantTxt += props.radio_variants[variantNum].name;
									}
								}
							}
							if(props.television && 'television_variants' in release && 'television_variants' in props && props.television_variants && props.television_variants.length) {
								for(variantNum in props.television_variants) {
									if(release.television_variants.includes(props.television_variants[variantNum]._id)) {
										if(variantTxt !== "") {
											variantTxt += "\n";
										}
										variantTxt += props.television_variants[variantNum].name;
									}
								}
							}
							if(props.advert && 'advert_variants' in release && 'advert_variants' in props && props.advert_variants && props.advert_variants.length) {
								for(variantNum in props.advert_variants) {
									if(release.advert_variants.includes(props.advert_variants[variantNum]._id)) {
										if(variantTxt !== "") {
											variantTxt += "\n";
										}
										variantTxt += props.advert_variants[variantNum].name;
									}
								}
							}
							return(
								<Table.Row key={release.id} onClick={(event) => handleClick(event, release.id)} className='browse-table-row'>
									<Table.Cell>{release.title}</Table.Cell>
									<Table.Cell>{release.artist}</Table.Cell>
									<Table.Cell>{release.date_display}</Table.Cell>
									<Table.Cell>{release.label}</Table.Cell>
									<Table.Cell>{release.country}</Table.Cell>
									<Table.Cell>{release.catalog}</Table.Cell>
									<Table.Cell>{release.format}</Table.Cell>
									<Table.Cell>
										{'status-official' in release && <>Official</>}
										{'status-bootleg' in release && <>Bootleg</>}
										{'status-unauthorised' in release && <>Unauthorised</>}
										{'status-promo' in release && <>Promo</>}
										{'status-demo' in release && <>Demo</>}
										{'status-private' in release && <>Private recording</>}
										{'status-club' in release && <>Club edition</>}
										{'status-exclusive' in release && <>Retailer exclusive</>}
										{'status-transcription' in release && <>Transcription</>}
										{'status-jukebox' in release && <>Jukebox</>}
									</Table.Cell>
									<Table.Cell>{release.pressing}</Table.Cell>
									{showVariantColumn && 
										<Table.Cell>{variantTxt}</Table.Cell>
									}
									<Table.Cell>
										<Link to={'/release/' + release.id}>view</Link>
									</Table.Cell>
								</Table.Row>
							);
						})}
					</Table.Body>
					<Table.Footer>
						<Table.Row>
							<Table.HeaderCell>
								Showing {first}-{last} of {total} releases.
							</Table.HeaderCell>
							<Table.HeaderCell colSpan='7' textAlign='right'>
								<Pagination activePage={state.page} totalPages={pages} firstItem={null} lastItem={null} onPageChange={handlePaginationClick} siblingRange={1} inverted/>
							</Table.HeaderCell>
						</Table.Row>
					</Table.Footer>
				</Table>
			</Segment>

			{showVariantModal && 
				<Modal
					dimmer='blurring'
					open={showVariantFilterModal}
					onClose={() => runVariantFilters()}
				>
					<Modal.Header>Variant Filter</Modal.Header>
					<Modal.Content>
						<Form>
							<Form.Group grouped>
								{('event_variants' in props && props.event_variants) && props.event_variants.map(function(vari) {
									return <Form.Field
										key={vari._id}
										control='input'
										type='radio'
										name='variantFilter'
										label={vari.name}
										checked={event_variant === vari._id}
										onChange={(e) => setEventVariant(vari._id)}
									/>
								})}
								{('film_variants' in props && props.film_variants) && props.film_variants.map(function(vari) {
									return <Form.Field
										key={vari._id}
										control='input'
										type='radio'
										name='variantFilter'
										label={vari.name}
										checked={film_variant === vari._id}
										onChange={(e) => setFilmVariant(vari._id)}
									/>
								})}
								{('radio_variants' in props && props.radio_variants) && props.radio_variants.map(function(vari) {
									return <Form.Field
										key={vari._id}
										control='input'
										type='radio'
										name='variantFilter'
										label={vari.name}
										checked={radio_variant === vari._id}
										onChange={(e) => setRadioVariant(vari._id)}
									/>
								})}
								{('television_variants' in props && props.television_variants) && props.television_variants.map(function(vari) {
									return <Form.Field
										key={vari._id}
										control='input'
										type='radio'
										name='variantFilter'
										label={vari.name}
										checked={television_variant === vari._id}
										onChange={(e) => setTelevisionVariant(vari._id)}
									/>
								})}
								{('advert_variants' in props && props.advert_variants) && props.advert_variants.map(function(vari) {
									return <Form.Field
										key={vari._id}
										control='input'
										type='radio'
										name='variantFilter'
										label={vari.name}
										checked={advert_variant === vari._id}
										onChange={(e) => setAdvertVariant(vari._id)}
									/>
								})}
							</Form.Group>
						</Form>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={() => clearVariantFilter()}>
							Reset filter
						</Button>
						<Button positive onClick={() => runVariantFilters()}>
							Filter
						</Button>
					</Modal.Actions>
				</Modal>
			}
		</div>
	);
}

										// <<a href={'http://localhost:8888/v2/release_v2.html?id=' + release.id} target="_blank">edit</a>-->
										// {'pending' in release && <!--<span>PENDING</span>}
