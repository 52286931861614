import React, { useState, useEffect, useCallback } from 'react';
import { API_ADDRESS, PAGE_TITLE_BASE } from './../constants.js';
import { Container, Grid, Card, Dimmer, Loader, Message } from 'semantic-ui-react'
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

export function Home(props) {
	document.title = PAGE_TITLE_BASE;

	const [loading, setLoading] = useState(true);
	const [searching, setSearching] = useState(false);
	const navigate = useNavigate();

	const [recordingCount, setRecordingCount] = useState('0');
	const [releaseCount, setReleaseCount] = useState('0');
	const [eventCount, setEventCount] = useState('0');
	const [merchandiseCount, setMerchandiseCount] = useState('0');
	const [sheetMusicCount, setSheetMusicCount] = useState('0');
	const [publicationCount, setPublicationCount] = useState('0');
	const [advertCount, setAdvertCount] = useState('0');
	const [songCount, setSongCount] = useState('0');
	const [soundtrackCount, setSoundtrackCount] = useState('0');
	const [peopleCount, setPeopleCount] = useState('0');
	const [albumCount, setAlbumCount] = useState('0');
	const [organisationCount, setOrganisationCount] = useState('0');

	const search = useCallback(() => {
		fetch(`${API_ADDRESS}/stats/`)
			.then(function(response) {
				return response.json()
			}).then(function(json) {
				setRecordingCount(parseInt(json.recordings).toLocaleString("en"));
				setReleaseCount(parseInt(json.releases).toLocaleString("en"));
				setEventCount(parseInt(json.events).toLocaleString("en"));
				setMerchandiseCount(parseInt(json.merchandise).toLocaleString("en"));
				setSheetMusicCount(parseInt(json.sheet_music).toLocaleString("en"));
				setPublicationCount(parseInt(json.publication).toLocaleString("en"));
				setAdvertCount(parseInt(json.adverts).toLocaleString("en"));
				setSongCount(parseInt(json.songs).toLocaleString("en"));
				setSoundtrackCount(parseInt(json.soundtracks).toLocaleString("en"));
				setPeopleCount(parseInt(json.people).toLocaleString("en"));
				setAlbumCount(parseInt(json.albums).toLocaleString("en"));
				setOrganisationCount(parseInt(json.organisations).toLocaleString("en"));
				setLoading(false);
			}).catch(function(ex) {
			})
	}, []);	

	useEffect(() => { if(loading && !searching ) { setSearching(true); search() } }, [loading, search, searching])

	const onClickHandler = (e, link) => {
		if(e.ctrlKey || e.metaKey){
			window.open(link, '_blank');
		} else {
			navigate(link);
		}
	}

	return (
		<Container>
			<Grid columns={2} stackable relaxed='very'>
				<Grid.Row>
					<Grid.Column>
						<Card fluid link inverted="inverted" onClick={(e) => onClickHandler(e, '/recording')}>
							<Card.Content header='Recordings and Performances' />
							<Card.Content description="Frank's recordings in the studio and film, broadcasts on radio and television and performances on stage" />
							<Card.Content extra>
								<Dimmer active={loading} inverted>
									<Loader size='small'>Loading</Loader>
								</Dimmer>
								{recordingCount} recordings and performances
							</Card.Content>
						</Card>
					</Grid.Column>
					<Grid.Column>
						<Card fluid link inverted="inverted" onClick={(e) => onClickHandler(e, '/release')}>
							<Card.Content header='Releases' />
							<Card.Content description="Frank on vinyl, cassette, CD, digital and more" />
							<Card.Content extra>
								<Dimmer active={loading} inverted>
									<Loader size='small'>Loading</Loader>
								</Dimmer>
								{releaseCount} releases
							</Card.Content>
						</Card>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>
						<Card fluid link inverted="inverted" onClick={(e) => onClickHandler(e, '/calendar')}>
							<Card.Content header='Events and More' />
							<Card.Content description="Day by Day Sinatra: recording sessions, broadcasts, concerts, photos, life events and more" />
							<Card.Content extra>
								<Dimmer active={loading} inverted>
									<Loader size='small'>Loading</Loader>
								</Dimmer>
								{eventCount} events and more
							</Card.Content>
						</Card>
					</Grid.Column>
					<Grid.Column>
						<Card fluid link inverted="inverted" onClick={(e) => onClickHandler(e, '/sheet_music')}>
							<Card.Content header='Sheet Music' />
							<Card.Content description="Sheet music" />
							<Card.Content extra>
								<Dimmer active={loading} inverted>
									<Loader size='small'>Loading</Loader>
								</Dimmer>
								{sheetMusicCount} music sheets and albums
							</Card.Content>
						</Card>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>
						<Card fluid link inverted="inverted" onClick={(e) => onClickHandler(e, '/merchandise')}>
							<Card.Content header='Merchandise' />
							<Card.Content description="Merchandise and memorabilia" />
							<Card.Content extra>
								<Dimmer active={loading} inverted>
									<Loader size='small'>Loading</Loader>
								</Dimmer>
								{merchandiseCount} merchandise
							</Card.Content>
						</Card>
					</Grid.Column>
					<Grid.Column>
						<Card fluid link inverted="inverted" onClick={(e) => onClickHandler(e, '/publication')}>
							<Card.Content header='Publications' />
							<Card.Content description="Books, magazines and more" />
							<Card.Content extra>
								<Dimmer active={loading} inverted>
									<Loader size='small'>Loading</Loader>
								</Dimmer>
								{publicationCount} books, magazines and other publications
							</Card.Content>
						</Card>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>
						<Card fluid link inverted="inverted" onClick={(e) => onClickHandler(e, '/advert')}>
							<Card.Content header='Adverts' />
							<Card.Content description="Adverts, film posters and more" />
							<Card.Content extra>
								<Dimmer active={loading} inverted>
									<Loader size='small'>Loading</Loader>
								</Dimmer>
								{advertCount} adverts
							</Card.Content>
						</Card>
					</Grid.Column>
					<Grid.Column>
						<Card fluid link inverted="inverted" onClick={(e) => onClickHandler(e, '/song')}>
							<Card.Content header='Songs' />
							<Card.Content description="Songs" />
							<Card.Content extra>
								<Dimmer active={loading} inverted>
									<Loader size='small'>Loading</Loader>
								</Dimmer>
								{songCount} songs
							</Card.Content>
						</Card>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>
						<Card fluid link inverted="inverted" onClick={(e) => onClickHandler(e, '/soundtrack')}>
							<Card.Content header='Soundtracks' />
							<Card.Content description="Soundtrack appearances on radio, film, TV and more" />
							<Card.Content extra>
								<Dimmer active={loading} inverted>
									<Loader size='small'>Loading</Loader>
								</Dimmer>
								{soundtrackCount} soundtrack appearances
							</Card.Content>
						</Card>
					</Grid.Column>
					<Grid.Column>
						<Card fluid link inverted="inverted" onClick={(e) => onClickHandler(e, '/person')}>
							<Card.Content header='People' />
							<Card.Content description="People" />
							<Card.Content extra>
								<Dimmer active={loading} inverted>
									<Loader size='small'>Loading</Loader>
								</Dimmer>
								{peopleCount} people
							</Card.Content>
						</Card>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>
						<Card fluid link inverted="inverted" onClick={(e) => onClickHandler(e, '/album')}>
							<Card.Content header='Albums' />
							<Card.Content description="Albums" />
							<Card.Content extra>
								<Dimmer active={loading} inverted>
									<Loader size='small'>Loading</Loader>
								</Dimmer>
								{albumCount} albums
							</Card.Content>
						</Card>
					</Grid.Column>
					<Grid.Column>
						<Card fluid link inverted="inverted" onClick={(e) => onClickHandler(e, '/organisation')}>
							<Card.Content header='Organisations' />
							<Card.Content description="Organisations" />
							<Card.Content extra>
								<Dimmer active={loading} inverted>
									<Loader size='small'>Loading</Loader>
								</Dimmer>
								{organisationCount} organisations
							</Card.Content>
						</Card>
					</Grid.Column>
				</Grid.Row>
			</Grid>

			<Grid style={{width:'50%', marginLeft: '25%'}}>
				<Grid.Row>
					<Message warning style={{textAlign:'center'}}>
						<Message.Header><Link to="/information_please">Information Wanted!</Link></Message.Header>
					</Message>
				</Grid.Row>
			</Grid>
		</Container>
	);
}